import React, { useCallback } from 'react'
import withList from '../../../../../pages/moduleable/modules/list/hoc/withList'
import ImageContainer from '../../../../../components/image/image-container'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import Product from '../../../../../desktop/pages/listing/components/item/index';
import ListSwiperProducts from './components/list-swiper-products'
import classes from './style.module.scss'
import useStyles from 'isomorphic-style-loader/useStyles'
import { getStyleObjectFromString } from '../../../../../utils/style'
import { Types } from './index'
import withObserver from '../../../../../pages/moduleable/modules/hoc/withObserver'
import { FormattedMessage } from 'react-intl'
import { getSensorsUrl } from '../../../../../utils/sensor';

const getColorTheme = (htmlString) => {
    // 使用正则表达式提取 bg 和 color 属性值
    const bgMatch = htmlString.match(/bg="([^"]*)"/);
    const colorMatch = htmlString.match(/cl="([^"]*)"/);

    // 获取属性值，如果匹配不到则返回空字符串
    const bgValue = bgMatch ? bgMatch[1] : '';
    const colorValue = colorMatch ? colorMatch[1] : '';

    return {
        bg: bgValue,
        cl: colorValue
    }
}

export default withList(withObserver(props => {
    useStyles(classes)
    const { products, data, sensors, innerRef } = props

    const styles = getStyleObjectFromString(data?.style)

    const listStyles = getStyleObjectFromString(data?.listStyle);

    const dark = (!!styles?.backgroundColor && "#fff" !== styles.backgroundColor.toLowerCase() ) || !!styles?.backgroundImage

    const url = data?.href

    const aspectRatio = data?.width && data?.height ? `${data?.width} / ${data?.height}` : undefined

    const { bg, cl } = getColorTheme(data?.styledTitle || '')
    // const { bg, cl } = getColorTheme(`<span bg="#ECFFDF" cl="#222">${data?.styledTitle}</span>`)

    const clickHandle = useCallback(e => {
        e.preventDefault()
        const href = e.currentTarget?.href
        window.location.href = getSensorsUrl(href, {
            resourcepage_title: sensors?.title,
            resource_type: '1',
            resource_content: data?.refId,
            resource_position: sensors?.position,
        })
    }, [])

    return <div ref={innerRef} id={data?.id} className={`${classes.ListContainer} ${!data?.full ? 'MaxWith' : ''}`} style={{ paddingTop: `${data?.marginTop}`, ...styles }}>


        {
            bg ? <div className={classes.BgTitle} style={{
                backgroundColor: bg,
            }}>
                <a style={{ color: cl || '#222' }} onClick={clickHandle} href={url} dangerouslySetInnerHTML={{ __html: data.styledTitle }} />
            </div> : <>
                {
                    data?.styledTitle && <div className={classes.Title}>
                        <a onClick={clickHandle} href={url} dangerouslySetInnerHTML={{ __html: data.styledTitle }} />
                    </div>
                }
            </>
        }

        {
            data?.src && <div className={classes.ListImage}>
                <ImageContainer style={{ aspectRatio }} href={url} sensors={{
                    ...sensors,
                    refId: data?.refId,
                    type: '1',
                }} position={0}>
                    <LazyLoadImage alt={data?.title} src={data?.src} />
                </ImageContainer>
            </div>
        }

        <div className={`${classes.List4Products}`} style={{ ...listStyles }}>
            <ListSwiperProducts
                items={products}
                slidesPerView={6}
                render={(item, index) => {
                    return <Product sensors={
                        {
                            resourcepage_title: sensors?.title,
                            resource_position: sensors?.position,
                            resource_content: data?.refId,
                            resource_type: '1',
                        }
                    } column={data?.refId} product={item} position={index} multicolorNotDisplay />
                }}
                outline={dark}
                moreUrl={getSensorsUrl(url, {
                    resourcepage_title: sensors?.title,
                    resource_type: '1',
                    resource_content: data?.refId,
                    resource_position: sensors?.position,
                })}
            />

        </div>
    </div>
}))