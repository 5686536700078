import React, { useEffect, useState, useCallback } from 'react'
import ImageContainer from '../../../../../components/image/image-container'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import ListSwiperProducts from './components/list-swiper-products'
import Product, { ViewMoreProductItem }  from '../../../../../desktop/pages/listing/components/item/index'
import SimpleProduct  from '../../../../../desktop/pages/listing/components/item/simple-item'

import classes from './style.module.scss'
import useStyles from 'isomorphic-style-loader/useStyles'
import withGroup from '../../../../../pages/moduleable/modules/list/hoc/withGroup'
import withObserver from '../../../../../pages/moduleable/modules/hoc/withObserver'
import { getStyleObjectFromString } from '../../../../../utils/style'
import withSource from '../../../../../hocs/source-scoll'
import withSwiper from './hoc/swiper'
import { useQuery } from '../../../../../hooks/common'


const Tab = withSource(props => {
    useStyles(classes)
    const { selectedItem, innerRef, sensors, item, handleClick, position, theme } = props
    const isActive = selectedItem?.id === item?.id
    return <span
        ref={innerRef}
        data-position={`${sensors?.position || 1}-${(position || 0) + 1}`}
        data-type={sensors?.type}
        data-content={item?.refId || item.title || item.name}
        data-title={sensors?.title}
        style={{
            borderColor: isActive ? theme?.activeBorderColor : theme?.borderColor,
            color: isActive ? theme?.activeTextColor : theme?.textColor
        }}
        onClick={() => { handleClick(item, position) }} className={`${classes.Tab} ${isActive ? classes.Selected : ''}`}>{item.title || item.name}</span>
})

const Tabs = withSwiper(props => {
    useStyles(classes)
    const { items } = props
    return <div className={classes.Festival2Tabs}>
        {
            items?.map((item, index) => <Tab key={index} {...props} item={item} position={index} />)
        }
    </div>
})


const CollectionImage = props => {
    const { collection, sensors } = props
    const aspectRatio = collection?.width && collection?.height ? `${collection?.width} / ${collection?.height}` : undefined
    return <ImageContainer style={{ aspectRatio }} href={collection?.href} sensors={sensors}>
        <LazyLoadImage alt={collection?.title} src={collection?.src} />
    </ImageContainer>
}


export default withGroup(withObserver(props => {
    useStyles(classes)
    const { collections, data, sensors, innerRef } = props
    const [collection, setCollection] = useState()
    const [position, setPosition] = useState(1)
    const query = useQuery()
    const id = data?.id
    const theme = data?.theme
    const dark = data?.dark

    const selectedIndex = query?.locationModule === id ? Number(query?.selectedIndex) : 0

    const styles = getStyleObjectFromString(data?.style)

    useEffect(() => {
        setCollection(collections?.[selectedIndex || 0] || collections?.[0])
    }, [collections])

    return <div ref={innerRef} id={data?.id} className={`${classes.ListContainerF} ${!data?.full ? 'MaxWith' : ''} ${collections?.length > 1?'':classes.Single}`} style={{ paddingTop: `${data?.marginTop}`, ...styles }}>

        {
            data?.styledTitle && <div className={classes.Title}>
                <span dangerouslySetInnerHTML={{ __html: data.styledTitle }} />
            </div>
        }

        <div className={classes.Container} style={{
            backgroundImage: `url(${data?.theme?.backgroundUrl})`,
        }}>
            <div className={classes.Hd}>
                <CollectionImage collection={collection} sensors={{
                    ...sensors,
                    title: collection?.title,
                    refId: collection?.refId || collection?.id,
                    position: `${sensors?.position}-${position}`,
                }} />
            </div>

            <div className={classes.Bd}>
                {
                    collections?.length > 1 && <div className={classes.SwiperContinaer}>
                        <Tabs
                            selectedIndex={selectedIndex}
                            sensors={
                                {
                                    ...sensors,
                                    type: '1',
                                }
                            }
                            theme={theme}
                            items={collections} onTab={
                                (item, index) => {
                                    setCollection(item)
                                    setPosition(index + 1)
                                }
                            } />
                    </div>
                }


                <ListSwiperProducts
                    options={{
                        loop: false
                    }}
                    innerCursor
                    moreUrl={collection?.href}
                    items={collection?.products}
                    slidesPerView={5}
                    render={(item, index) => {
                        return <React.Fragment>
                            {
                                item?.id ?
                                    <SimpleProduct sensors={
                                        {
                                            resourcepage_title: sensors?.title,
                                            resource_position: `${sensors?.position}-${position}`,
                                            resource_content: collection?.refId || collection?.id,
                                            resource_type: '1',
                                        }
                                    } column={collection?.refId || collection?.id || data?.refId} product={item} position={index} dark={dark}/> :
                                    <ViewMoreProductItem
                                        sensors={
                                            {
                                                resourcepage_title: sensors?.title,
                                                resource_position: `${sensors?.position}-${position}`,
                                                resource_content: collection?.refId || collection?.id,
                                                resource_type: '1',
                                            }
                                        } column={collection?.refId || collection?.id || data?.refId}
                                        item={item}
                                        position={index}
                                    />
                            }
                        </React.Fragment>
                    }}
                />
            </div>
        </div>
    </div>
}))