import React, { useEffect, useState, useCallback } from 'react'
import ImageContainer from '../../../../../components/image/image-container'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import ListProducts from './components/list-products'
import classes from './style.module.scss'
import useStyles from 'isomorphic-style-loader/useStyles'
import withGroup from '../../../../../pages/moduleable/modules/list/hoc/withGroup'
import { FormattedMessage } from 'react-intl'
import withObserver from '../../../../../pages/moduleable/modules/hoc/withObserver'
import { getStyleObjectFromString } from '../../../../../utils/style'
import withSource from '../../../../../hocs/source-scoll'
import withSwiper from './hoc/swiper'
import { useQuery } from '../../../../../hooks/common'
import ViewMore from '../../../../../pages/moduleable/modules/list/components/view-more'


const Tab = withSource(props => {
    useStyles(classes)
    const { selectedItem, innerRef, sensors, item, handleClick, position, theme } = props
    const isActive = selectedItem?.id === item?.id
    return <span
        ref={innerRef}
        data-position={`${sensors?.position || 1}-${(position || 0) + 1}`}
        data-type={sensors?.type}
        data-content={item?.refId || item.title || item.name}
        data-title={sensors?.title}
        style={{
            backgroundColor: isActive ? theme?.activeBackgroundColor : theme?.backgroundColor,
            borderColor: isActive ? (theme?.activeBorderColor || theme?.activeBackgroundColor) : theme?.borderColor,
            color: isActive ? theme?.activeTextColor : theme?.textColor
        }}
        onClick={() => { handleClick(item, position) }} className={`${classes.Tab} ${isActive ? classes.Selected : ''}`}>{item.title || item.name}</span>
})

const Tabs = withSwiper(props => {
    useStyles(classes)
    const { items } = props
    return <div className={classes.FestivalTabs}>
        {
            items?.map((item, index) => <Tab key={index} {...props} item={item} position={index} />)
        }
    </div>
})


export default withGroup(withObserver(props => {
    useStyles(classes)
    const { collections, data, sensors, innerRef } = props
    const [collection, setCollection] = useState()
    const [position, setPosition] = useState(1)
    const query = useQuery()
    const id = data?.id
    const theme = data?.theme

    const selectedIndex = query?.locationModule === id ? Number(query?.selectedIndex) : 0

    const styles = getStyleObjectFromString(data?.style)

    useEffect(() => {
        setCollection(collections?.[selectedIndex || 0] || collections?.[0])
    }, [collections])

    const aspectRatio = data?.width && data?.height ? `${data?.width} / ${data?.height}` : undefined

    return <div ref={innerRef} id={data?.id} className={`${classes.ListContainer} ${!data?.full ? 'MaxWith' : ''}`} style={{ paddingTop: `${data?.marginTop}`, ...styles }}>

        {
            data?.styledTitle && <div className={classes.Title}>
                <span dangerouslySetInnerHTML={{ __html: data.styledTitle }} />
            </div>
        }

        {
            data?.src && <a href={data?.href} style={{ marginBottom: 12 }} className={classes.ListImage}>
                <ImageContainer style={{ aspectRatio }} sensors={{
                    ...sensors,
                    refId: data?.refId,
                    type: '1',
                }}>
                    <LazyLoadImage alt={data?.title} src={data?.src} />
                </ImageContainer>
            </a>
        }

        {
            collections?.length > 1 && <div>
                <Tabs
                    selectedIndex={selectedIndex}
                    sensors={
                        {
                            ...sensors,
                            type: '1',
                        }
                    }
                    theme={theme}
                    items={collections} onTab={
                        (item, index) => {
                            setCollection(item)
                            setPosition(index + 1)
                        }
                    } />
            </div>
        }


        <div className={classes.FestivalWrapper}>
            <div className={`${classes.FridayProducts} ${classes.Festival}`} style={{ marginTop: 0 }}>
                <ListProducts festival className={classes.Products} sensors={
                    {
                        resourcepage_title: sensors?.title,
                        resource_position: `${sensors?.position}-${position}`,
                        resource_content: collection?.refId || collection?.id,
                        resource_type: '1',
                    }
                } column={collection?.refId || collection?.id} products={collection?.products} />

                <div className={classes.ViewAll}>
                    <ViewMore sensors={
                        {
                            resourcepage_title: sensors?.title,
                            resource_position: `${sensors?.position}-${position}`,
                            resource_content: collection?.refId || collection?.id,
                            resource_type: '1',
                        }
                    } className={classes.ViewMore} href={collection?.href}><FormattedMessage id="view_all" defaultMessage="View All" />{' >'}</ViewMore>
                </div>
            </div>
        </div>

    </div>
}))