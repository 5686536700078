import React, { useState } from 'react'
import EmptySwiper from "../../../../../../../components/swiper/autowidth";
import SimpleProduct, { EmptyItem } from '../../../../../listing/components/item/landing-item'
import classes from './style.module.scss'
import useStyles from 'isomorphic-style-loader/useStyles'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Iconfont } from '../../../../../../../components/icon/iconfont'
import { FormattedMessage } from 'react-intl';


const SwiperCom = props => {
    useStyles(classes);
    const { items, options, className, style, moreUrl, outline } = props
    const { slidesPerView = 5 } = options;


    return <Swiper
        {...options}
        className={className}
        style={style}
    >
        {
            items?.map((item, index) => <SwiperSlide className={`${classes.SwiperSlide} ${outline? classes.Outline : ''}`} key={item?.id || item?.refId || index} style={{ maxWidth: `${100 / slidesPerView}%` }}>
                {
                    props.render(item, index)
                }
            </SwiperSlide>)
        }

        {
            moreUrl && <SwiperSlide className={`${classes.SwiperSlide}`} style={{ maxWidth: `${100 / slidesPerView}%` }}>
                <a className={`${classes.ViewMoreProduct} ${outline? classes.Outline : ''}`} href={moreUrl}>
                    <div className={classes.Text}>
                        <FormattedMessage id="view_more" defaultMessage="View More" />{'>'}
                    </div>
                </a>
            </SwiperSlide>
        }


    </Swiper>
}

export default props => {
    useStyles(classes);
    const {
        items,
        slidesPerView,
        slidesPerGroup,
        options,
        showBgNavigation,
        prevStyles = {},
        nextStyles = {},
        moreUrl,
        outline,
        innerCursor,
    } = props
    const { container: prevContainerStyle, icon: prevIconStyle } = prevStyles;
    const { container: nextContainerStyle, icon: nextIconStyle } = nextStyles;
    const [swiper, setSwiper] = useState()
    const [end, setEnd] = useState(false)
    const [begin, setBegin] = useState(true)

    const clickHandle = (flag) => {
        if (!!flag) {
            swiper.slidePrev(500);
        } else {
            swiper.slideNext(500);
        }
    }

    const slideChangeHandle = s => {
        // if(moreUrl){
        // }
        if (s.isEnd) {
            setEnd(true)
        } else if (s.isBeginning) {
            setBegin(true)
        } else {
            setEnd(false)
            setBegin(false)
        }
    }

    return <div className={`${classes.SwiperProducts} ${innerCursor? classes.InnerCursor:''} ${classes.BgNavigation} ${props.className}`}>
        {
            items && items.length > 0 ? <React.Fragment>
                <SwiperCom options={{
                    slidesPerView: slidesPerView || 5,
                    slidesPerGroup: slidesPerGroup ? slidesPerGroup : slidesPerView || 5,
                    spaceBetween: 10,
                    onSwiper: (s) => setSwiper(s),
                    navigation: {
                        prevEl: null,
                        nextEl: null,
                    },
                    onSlideChange: slideChangeHandle,
                    ...options
                }} moreUrl={moreUrl} outline={outline} items={items} render={(item, index) => props.render(item, index)} style={props.style} />

                {
                    !begin && <span className={`${classes.Prev}`} style={prevContainerStyle} onClick={() => clickHandle(true)}>
                        <Iconfont style={prevIconStyle}>&#xe693;</Iconfont>
                    </span>
                }

                {
                    !end && <span className={`${classes.Next}`} style={nextContainerStyle} onClick={() => clickHandle(false)}>
                        <Iconfont style={nextIconStyle}>&#xe694;</Iconfont>
                    </span>
                }


            </React.Fragment> : <EmptySwiper options={{
                slidesPerView: slidesPerView || 5,
                spaceBetween: 10
            }} items={[1, 1, 1, 1, 1, 1]} render={(item, index) => {
                return <EmptyItem />
            }} />
        }
    </div>
}